export const Converter = {
  methods: {
    checkValue (value, def) {
      if (isNaN(parseFloat(value))) return def
      else return value
    },
    convert (value, type, options) {
      let result
      let temp
      switch (type) {
        case 'number':
          result = Number(value)
          break
        case 'short':
          result = Number(value)
          if (result > 32768) result -= 65536
          break
        case 'control4':
          temp = Number(value)
          result = {
            auto: (temp & 1) === 1,
            manual: ((temp >> 1) & 1) === 1,
            active: ((temp >> 2) & 1) === 1,
            inactive: ((temp >> 3) & 1) === 1
          }
          break
        case 'control3':
          temp = Number(value)
          result = {
            active: (temp & 1) === 1,
            auto: ((temp >> 1) & 1) === 1,
            manual: ((temp >> 2) & 1) === 1
          }
          break
        case 'control2':
          temp = Number(value)
          result = {
            auto: ((temp >> 1) & 1) === 1,
            manual: ((temp >> 2) & 1) === 1
          }
          break
        case 'season':
          result = Number(value) === 1 ? 'лето' : 'зима'
          break
        case 'bit':
          temp = Number(value)
          result = ((temp >> options.pos) & 1) === 1
          break
        case 'bit_inverse':
          temp = Number(value)
          result = ((temp >> options.pos) & 1) !== 1
          break
        default:
          result = value
      }
      if (options !== undefined) {
        if (options.multiplier !== undefined) result *= options.multiplier
        if (options.delimiter !== undefined) result /= options.delimiter
        if (options.precision !== undefined) result = result.toFixed(options.precision)
      }
      return result
    },
    getCommand (type, value) {
      let result
      switch (type) {
        case 'control4':
          result = 2
          if (value.active) return (result & (~4)) | 8
          else return (result & ~(8)) | 4
        case 'test1':
          break
        case 'test2':
          break
      }
    },
    getCommandDesired (type, value) {
      switch (type) {
        case 'control4_variant':
          if (value.active) return { command: 10, desired: undefined }
          else return { command: 9, desired: 5 }
        case '11M_GVS':
          if (value.auto) return { command: 4, desired: value.current | 2 }
          else if (value.active) return { command: 9, desired: 5 }
          else return { command: 10, desired: 4 }
        case 'ahu18':
          if (value.active) return { command: 1, desired: value.current | 1 }
          else return { command: 2, desired: value.current & ~1 }
        case 'setpoint':
          return { command: value, desired: value }
      }
    },
    getMultistateSuffix (el, state) {
      switch (el) {
        case 'solenoid':
          if (state.active === undefined) return '_neutral'
          else if (state.active === true) return '_open'
          else return '_close'
        case 'season':
          if (state === '1') return '_summer'
          else if (state === '0') return '_winter'
          else return '_unknown'
      }
    }
  }
}
