<template>
  <div id="app">
    <div id="content">
      <component :is="currentView" :schema="componentProps" v-on:navigate="setView" v-on:back="back" v-on:createView="createView" />
    </div>
    <div id="bottom-panel">
      <alarm-log v-if="isAlarmLogVisible"></alarm-log>
    </div>
  </div>
</template>

<script>
import FaceplateAhu from './components/FaceplateAhu.vue'
import FaceplateValve from './components/FaceplateValve.vue'
import FaceplateSeason from './components/FaceplateSeason.vue'
import FaceplateSpOnOff from './components/FaceplateSpOnOff.vue'
import FaceplateOnOffAuto from './components/FaceplateOnOffAuto.vue'
import FaceplateLogs from './components/FaceplateLogs.vue'
import FaceplateValveChart from './components/FaceplateValveChart.vue'
import AlarmLog from './components/AlarmLog.vue'
import AlarmScroll from './components/AlarmScroll.vue'
import EventScroll from './components/EventScroll.vue'
import LoginView from './components/LoginView.vue'
import MainView from './components/MainView.vue'
import ChillerView from './components/ChillerView.vue'
import Ahu4View from './components/Ahu4View.vue'
import Ahu18View from './components/Ahu18View.vue'
import Ahu3View from './components/Ahu3View.vue'
import Ahu1View from './components/Ahu1View.vue'
import Ahu2View from './components/Ahu2View.vue'
import MixView from './components/MixView.vue'
import HeatView from './components/HeatView.vue'
import VentView from './components/VentView.vue'
import GvsView from './components/GvsView.vue'
import FloorView from './components/FloorView.vue'
import PumpsView from './components/PumpsView.vue'

export default {
  name: 'App',
  components: {
    FaceplateAhu,
    FaceplateValve,
    FaceplateSeason,
    FaceplateSpOnOff,
    FaceplateOnOffAuto,
    FaceplateLogs,
    FaceplateValveChart,
    AlarmLog,
    AlarmScroll,
    EventScroll,
    LoginView,
    MainView,
    ChillerView,
    Ahu4View,
    Ahu18View,
    Ahu3View,
    Ahu1View,
    Ahu2View,
    MixView,
    HeatView,
    VentView,
    GvsView,
    FloorView,
    PumpsView
  },
  data () {
    return {
      currentView: 'MainView',
      previousView: 'MainView',
      componentProps: undefined
    }
  },

  computed: {
    isAlarmLogVisible () {
      return [
        'FaceplateValve',
        'FaceplateAhu',
        'FaceplateSeason',
        'FaceplateSpOnOff',
        'FaceplateOnOffAuto',
        'FaceplateLogs',
        'FaceplateValveChart',
        'LoginView',
        'EventScroll',
        'AlarmScroll'
      ].filter(x => x === this.currentView).length === 0
    }
  },

  methods: {
    setView (view) {
      if (this.currentView !== 'LoginView') this.previousView = this.currentView
      this.currentView = view
    },

    back () {
      this.setView(this.previousView)
    },

    createView (data) {
      this.componentProps = data.props
      this.setView(data.type)
    }
  }
}
</script>

<style>
@font-face {
    font-family: 'Digital';
    src: url('./assets/digital-7.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: '7Segment';
    src: url('./assets/7-segment.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body, html{
  color: #000;
  background-color: #fee0aaff;
  display: flex;
  flex-direction: column;
  margin: 0%;
  width: 100%;
  height: 100%;
}

:root {
  --side: calc((98vh - 60px) * 18/9);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

#content {
  width: var(--side);
  margin: auto;
  flex: 0 0 auto;
}

#bottom-panel {
  width: var(--side);
  height: auto;
  margin: 0 auto 0 auto;
  flex: 0 1 auto;
}

.button{
  fill: #0000ff01;
}
.button:hover {
  fill: #0000ff40;
}
.value{
  fill: #4f0070;
  font-size: 1.2em;
  font-family: 'Digital';
}
.level{
  fill: #4d4e49;
}
.valve{
  fill: grey;
}
.open{
  fill: yellow;
}
.close{
  fill:brown;
}
.label{
  font-family: Arial, Helvetica, sans-serif;
  display: none;
}
.auto{
  fill:#4d4e49;
  display: block;
}
.manual{
  fill:darkviolet;
  display: block;
}
.text-small{
  font-size: 50%;
}
.text-medium{
  font-size: 75%;
}
.text-large{
  font-size: 100%;
}
.alarm{
  display: none;
  fill: red;
}
.invisible {
  display: none;
}
.visible{
  display: block;
}
.transparent {
  fill: transparent;
}
.active{
  fill: #00ff0030
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 20px;
}

.circle-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, .2);
  border-top: 5px solid #fff;
  animation: animate 1.5s infinite linear;
}

@keyframes animate {
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

@media screen and (orientation: portrait) {
  #main_landscape {
    display: none;
  }
}

@media screen and (orientation: portrait) and (max-aspect-ratio: 9/16) {
  :root {
    --side: calc((98vh - 60px) * 9/16)
  }
}

@media screen and (orientation: portrait) and (min-aspect-ratio: 9/18) {
  :root {
    --side: calc((98vh) * 9/18)
  }
}

@media screen and (orientation: landscape) {
  #main_portrait {
    display: none;
  }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 17/9) {
  :root {
    --side: calc((98vh - 60px) * 18/9)
  }
}

@media screen and (orientation: landscape) and (max-aspect-ratio: 16/9) {
  :root {
    --side: calc(98vw)
  }
}

</style>
