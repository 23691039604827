<template>
  <div id="faceplate">
    <div id="header">
      <span>{{ title }}</span>
      <div id="close" @click="$emit('navigate', parent)"></div>
    </div>
    <div id="mode">{{ getValue(values.find(x => x.target === 'mode')).auto === true ? 'Автоматический' : '' }}{{ getValue(values.find(x => x.target === 'mode')).manual === true ? 'Ручной' : '' }}</div>
    <div id="body">
      <fieldset>
        <div class="row">
          <div class="row-label"><span class="par-label">TE_PV</span></div>
          <input :value="getValue(values.find(x => x.target === 'te_pv'))" disabled type="number">
        </div>
        <div class="row">
          <div class="row-label"><span class="par-label">TE_SP</span></div>
          <input v-model="te_sp" type="number" step="0.1">
        </div>
        <div class="row">
          <div class="row-label"><span class="par-label">OUT</span></div>
          <input :value="getValue(values.find(x => x.target === 'out'))" disabled type="number" >
        </div>
        <div class="row">
          <div class="row-label"><span class="par-label">Manual</span></div>
          <input v-model="pos_sp" type="number" step="0.1">
        </div>
      </fieldset>
    </div>
    <div id="controls">
      <div class="btn"  @click="sendCommand(tags.find(x => x.name === values.find(y => y.target === 'mode').cmd), 4, tags.find(x => x.name === values.find(y => y.target === 'mode').tag))">АВТО</div>
      <div class="btn" @click="sendCommand(tags.find(x => x.name === values.find(y => y.target === 'mode').cmd), 8, tags.find(x => x.name === values.find(y => y.target === 'mode').tag))">РУЧНОЙ</div>
      <div v-if="schema.chart !== undefined" class="btn" @click="$emit('createView', schema.chart)">ГРАФИК</div>
    </div>
  </div>
</template>

<script>
import { Main } from '../mixins/main'

export default {
  name: 'FaceplateValve',
  mixins: [
    Main
  ],

  props: {
    schema: {
      type: Object,
      require: true
    }
  },

  data () {
    return {
      hasNoAnimation: true
    }
  },

  computed: {
    title () {
      return this.schema.name
    },
    parent () {
      return this.schema.parent
    },
    tags () {
      return this.$store.getters.getTags
    },
    values () {
      return this.schema.values
    },
    te_sp: {
      get () {
        return this.tags.find(x => x.name === this.values.find(y => y.target === 'te_sp').tag).value
      },
      set (val) {
        this.sendCommand(
          this.tags.find(x => x.name === this.values.find(y => y.target === 'te_sp').cmd),
          this.getCommandDesired('setpoint', val),
          this.tags.find(x => x.name === this.values.find(y => y.target === 'te_sp').tag)
        )
      }
    },
    pos_sp: {
      get () {
        return this.tags.find(x => x.name === this.values.find(y => y.target === 'pos_sp').tag).value
      },
      set (val) {
        this.sendCommand(
          this.tags.find(x => x.name === this.values.find(y => y.target === 'pos_sp').cmd),
          this.getCommandDesired('setpoint', val),
          this.tags.find(x => x.name === this.values.find(y => y.target === 'pos_sp').tag)
        )
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#faceplate {
  margin: auto;
  width: 300px;
  background: transparent;
  border: 2px solid #4d4e49;
  border-radius: 20px 20px;
  padding: 0;
  position: relative;
}

#header {
  padding: 20px 0;
  text-align: center;
}

#close{
    position: absolute;
    top: -1px;
    right: -1px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
}

#close:before,
#close:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 5px;
    width: 32px;
    height: 1px;
    background: #4d4e49;
}

#close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#mode {
  text-align: center;
  margin: 10px 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #4d4e49;
  border-radius: 8px;
  color: #4f0070;
}

fieldset {
  border: none;
  margin: 0;
}

#faceplate .row {
  text-align: right;
}

.row-label {
  display: inline;
}

#controls {
  text-align: center;
}

.btn {
  height: 30px;
  line-height: 30px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: #4d4e49;
  color: white;
}

#faceplate input {
  font-family: '7Segment';
  outline: none;
  -webkit-appearance: none;
}

#faceplate input[type="number"] {
  border: 1px solid #4d4e49;
  background-color: transparent;
  padding: 12px 10px;
  margin-bottom: -1px;
  margin-left: 10px;
  width: 90px;
  color: #4f0070;
  font-size: 1.5rem;
  text-align: center;
}

</style>
