import { HTTP } from '../http-common'
import { Animation } from '../mixins/animation'
import { Converter } from '../mixins/converter'

export const Main = {
  data () {
    return {
      timerMain: undefined,
      timerAnimate: undefined,
      pulseTick: false
    }
  },
  mixins: [
    Animation,
    Converter
  ],

  beforeMount () {
    this.main()
    this.timerMain = setInterval(this.main, 1000)
    if (this.hasNoAnimation === undefined || this.hasNoAnimation === false) this.timerAnimate = setInterval(this.animate, 5)
  },

  beforeDestroy () {
    clearInterval(this.timerMain)
    clearInterval(this.timerAnimate)
  },

  computed: {
    getTags () {
      return this.tags !== undefined ? this.tags : []
    },
    getAlarms () {
      return this.alarms !== undefined ? this.alarms : []
    },
    getStatic () {
      return this.static !== undefined ? this.static : []
    },
    getNavigation () {
      return this.navigation !== undefined ? this.navigation : []
    },
    getValues () {
      return this.values !== undefined ? this.values : []
    },
    getLevels () {
      return this.levels !== undefined ? this.levels : []
    },
    getValves () {
      return this.valves !== undefined ? this.valves : []
    },
    getStatuses () {
      return this.statuses !== undefined ? this.statuses : []
    },
    getRollings () {
      return this.rollings !== undefined ? this.rollings : []
    },
    getStrokings () {
      return this.strokings !== undefined ? this.strokings : []
    },
    getRotations () {
      return this.rotations !== undefined ? this.rotations : []
    },
    getFlutters () {
      return this.flutters !== undefined ? this.flutters : []
    },
    getDampers () {
      return this.dampers !== undefined ? this.dampers : []
    },
    getMultistates () {
      return this.multistates !== undefined ? this.multistates : []
    },
    getFaceplates () {
      return this.faceplates !== undefined ? this.faceplates : []
    },
    isDataReliable () {
      return this.$store.getters.isDataReliable
    }
  },

  methods: {
    main () {
      this.$store.dispatch('fetchTags')
    },
    animate () {
      this.pulseTick = this.getPulseTick()
      this.getRotations.forEach(element => {
        if ((element.type === 'bit' && this.getValue(element) === true) || (this.getValue(element).active)) {
          element.angle += element.reverse !== undefined && element.reverse ? element.speed * -1 : element.speed
        }
      })
      const circles = [this.getStrokings, this.getRollings]
      circles.forEach(group => {
        group.forEach(element => {
          if (this.getValue(element).active) element.angle = (element.angle + element.speed) % 360
        })
      })
      this.getDampers.forEach(dmp => {
        if (dmp.type === 'bit') dmp.angle = this.checkValue(Number(this.getValue(dmp)), 0) * dmp.step
        else dmp.angle = this.checkValue(this.getValue(dmp), 0) * dmp.step + dmp.shift
      })
      this.getFlutters.forEach(flt => {
        flt.angle = this.getValue(flt) === true ? this.random(-1, 1) * flt.drift : flt.tilt - 2 * flt.tilt * this.checkValue(Number(flt.reverse), 0)
      })
    },
    getValue (data) {
      try {
        const value = this.$store.getters.getTags.find(x => x.name === data.tag).value
        if (value === undefined) return data.tag
        let result = this.convert(value, data.type, data.options)
        if (data.postfix !== undefined) result += data.postfix
        return result
      } catch (ex) {
        console.log(ex.message + data.tag)
        return 0
      }
    },
    random (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    trigger (element) {
      const tag = this.$store.getters.getTags.find(x => x.name === element.tag)
      const cmd = this.getCommandDesired(element.cmd_type, this.getValue(element))
      HTTP.post('/command/', JSON.stringify({
        tag: tag.cmd,
        value: cmd.command,
        token: this.$store.state.token
      }))
        .then(response => {
          if (response.data.AUTH_REQUIRED !== undefined) this.$emit('navigate', 'LoginView')
          else if (response.data.ERROR !== undefined) console.log(response.data.ERROR)
          else {
            tag.value = tag.desired = cmd.desired
            tag.protect = Date.now() + 10000
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    sendCommand (cmd, setpoint, tag = undefined) {
      HTTP.post('/command/', JSON.stringify({
        tag: cmd.name,
        value: setpoint.command,
        token: this.$store.state.token
      }))
        .then(response => {
          if (response.data.AUTH_REQUIRED !== undefined) this.$emit('navigate', 'LoginView')
          else if (response.data.ERROR !== undefined) console.log(response.data.ERROR)
          else if (setpoint.desired !== undefined && tag !== undefined) {
            tag.value = tag.desired = setpoint.desired
            tag.protect = Date.now() + 10000
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
