import { HTTP } from '../http-common'
import Chart from '../components/Chart.vue'
import 'chartjs-adapter-date-fns'
import { ru } from 'date-fns/locale'

export const GvsChart = {
  components: {
    Chart
  },

  data () {
    return {
      addDays: 0,
      loaded: false,
      chartdata: null,
      colors: [
        '#ff000030',
        '#0000ff30',
        '#72828830'
      ],
      plugins: [{
        id: 'vertical-line',
        afterDatasetsDraw (chart) {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0]
            const ctx = chart.ctx
            const yAxis = chart.scales['y-axis-0']
            const x = activePoint.tooltipPosition().x
            const topY = yAxis.top
            const bottomY = yAxis.bottom
            // draw line
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x, topY)
            ctx.lineTo(x, bottomY)
            ctx.lineWidth = 2
            ctx.strokeStyle = '#4d4e49'
            ctx.stroke()
            ctx.restore()
          }
        }
      }],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 1,
              displayFormats: {
                hour: 'HH:mm'
              },
              tooltipFormat: 'dd.MM.yyyy HH:mm'
            },
            adapters: {
              date: {
                locale: ru
              }
            }
          }]
        },
        animation: {
          duration: 1000
        },
        elements: {
          point: {
            radius: 1
          }
        },
        tooltips: {
          mode: 'nearest',
          intersect: false,
          axis: 'x'
        }
      }
    }
  },

  computed: {
    displayDate () {
      if (this.addDays === undefined) return new Date()
      const d = new Date()
      d.setDate(d.getDate() + this.addDays)
      return d
    }
  },

  methods: {
    main () {
      const start = this.getStartTime()
      const request = {
        tags: this.tags.map(x => x.name),
        start: start,
        end: start + this.gap
      }
      HTTP.post('data/historical/', JSON.stringify(request))
        .then(response => {
          this.parseData(response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },

    getStartTime () {
      if (this.addDays === 0) return Math.round(new Date().getTime() / 1000) - this.gap
      else {
        const d = new Date()
        d.setDate(d.getDate() + this.addDays)
        d.setHours(0, 0, 0)
        return Math.round(d.getTime() / 1000)
      }
    },

    moveOffset (d) {
      this.addDays += d
      if (this.addDays > 0) this.addDays = 0
      this.main()
    },

    parseData (raw) {
      // console.log(raw)
      if (raw === null) {
        this.chartdata = {
          labels: undefined,
          datasets: []
        }
        return
      }
      const chartdata = {
        labels: undefined,
        datasets: []
      }
      let cnt = 0
      this.tags.forEach(el => {
        if (raw[el.name] !== undefined) {
          if (chartdata.labels === undefined) {
            chartdata.labels = raw[el.name].values.map(x => (x.d * 1000))
          } else {
            chartdata.labels = this.mergeTicks(chartdata.labels, raw[el.name].values.map(x => (x.d * 1000)))
          }
        }
      })
      this.tags.forEach(el => {
        if (raw[el.name] !== undefined) {
          chartdata.datasets.push({
            label: el.title,
            borderColor: this.colors[cnt++],
            fill: false,
            data: this.mergeDataset(chartdata.labels, raw[el.name].values).map(x => x.v)
          })
        }
      })
      this.chartdata = chartdata
      this.loaded = true
    },

    mergeTicks (t1, t2) {
      const result = t1.map(x => x)
      t2.forEach(tick => {
        const el = t1.find(x => x === tick)
        if (el === undefined) {
          result.push(tick)
        }
      })
      return result.sort()
    },

    mergeDataset (ticks, data) {
      const result = []
      ticks.forEach(tick => {
        const el = data.find(x => Number(x.d) * 1000 === tick)
        if (el !== undefined) {
          result.push({
            d: tick,
            v: el.v
          })
        } else {
          result.push({
            d: tick,
            v: 0
          })
        }
      })
      return result
    },

    formatDate (date) {
      var d = new Date(date)
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
    }
  }
}
