<template>
  <div id="faceplate">
    <div id="header">
      <span v-text="title"></span>
      <span v-if="addDays !== 0" v-text="' | ' + displayDate.toLocaleDateString()"></span>
      <span v-else  v-text="' | Минувшие сутки'"></span>
      <div id="prev" @click="moveOffset(-1)"></div>
      <div id="next" @click="moveOffset(1)"></div>
      <div id="close" @click="$emit('navigate', parent)"></div>
    </div>
    <div id="body">
      <chart id="chart"
        v-if="loaded"
        :chartData="chartdata"
        :options="options"
        :plugins="plugins"/>
    </div>
    <div id="loader">
      <div ref="infinitescrolltrigger" id="scoll-trigger"></div>
      <div class="circle-loader" v-if="!loaded"></div>
    </div>
  </div>
</template>

<script>
import { GvsChart } from '../mixins/gvs-chart'

export default {
  name: 'FaceplateValveChart',
  mixins: [GvsChart],
  props: ['schema'],

  computed: {
    title () {
      return this.schema.name
    },
    parent () {
      return this.schema.parent
    },
    tags () {
      return this.schema.tags
    },
    gap () {
      return this.schema.gap
    }
  },

  mounted () {
    this.main()
  }
}
</script>

<style scoped>
  #faceplate {
    margin: 10px auto;
    height: calc(100vh - 30px);
    background: transparent;
    border: 2px solid #4d4e49;
    border-radius: 20px 20px;
    padding: 0;
    position: relative;
    text-align: center;
  }

  #header {
    padding: 20px 0;
    text-align: center;
  }

  #prev{
    position: absolute;
    top: -1px;
    right: 84px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
  }

  #prev:before,
  #prev:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 12px;
    width: 16px;
    height: 1px;
    background: #4d4e49;
  }

  #prev:before {
    transform-origin: left;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  #prev:after {
    transform-origin: left;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  #next{
    position: absolute;
    top: -1px;
    right: 42px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
  }

  #next:before,
  #next:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 12px;
    width: 16px;
    height: 1px;
    background: #4d4e49;
  }

  #next:before {
    transform-origin: right;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  #next:after {
    transform-origin: right;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  #close{
    position: absolute;
    top: -1px;
    right: -1px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
  }

  #close:before,
  #close:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 5px;
    width: 32px;
    height: 1px;
    background: #4d4e49;
  }

  #close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #body {
    height: calc(100% - 68px);
  }

  #chart {
    height: 100%;
  }

  #loader {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 20px;
  }

  #scroll-trigger {
    height: 50px;
  }
  .circle-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .2);
    border-top: 5px solid #fff;
    animation: animate 1.5s infinite linear;
  }
  @keyframes animate {
    0% {
      transform: translate(-50%,-50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%,-50%) rotate(360deg);
    }
  }
  @media screen and (orientation: portrait) and (max-width: 1024px) {
  #faceplate {
    font-size: 50%;
  }
}
</style>
