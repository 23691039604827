<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'FloorView',
  extends: BaseView,

  data () {
    return {
      layout: '12_FLOOR',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 194, y: 314 }, p: { x: 470, y: 92 }, align: 'start', tag: 'SP_5Y2', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 780, y: 342 }, p: { x: 640, y: 112 }, align: 'start', tag: 'TE_5Y2', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 540, y: 622 }, p: { x: 635, y: 392 }, align: 'start', tag: 'Position_5Y2', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 468, y: 610, w: 68, h: 13 }, p: { x: 558, y: 381, w: 68, h: 12 }, max: 100, tag: 'Position_5Y2' }
      ],
      valves: [
        { l: { x: 500, y: 645, r: 9 }, p: { x: 589, y: 415, r: 9 }, tag: 'REG_5Y2_STS', type: 'control2' }
      ],
      rotations: [
        { l: { x: 276, y: 570 }, p: { x: 365, y: 340 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_1M2_FL_STS', type: 'control3', el: 'pump_74' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 480, y: 630, w: 45, h: 50 },
          p: { x: 570, y: 400, w: 45, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'Тёплый пол 5Y2',
              parent: 'FloorView',
              values: [
                { target: 'mode', tag: 'REG_5Y2_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_5Y2', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_5Y2', type: 'short', cmd: 'SP_5Y2', cmd_type: 'value' },
                { target: 'out', tag: 'Position_5Y2', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_5Y2', type: 'short', cmd: 'Manual_5Y2', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'Тёплый пол 5Y2',
                  parent: 'FloorView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_5Y2', title: 'Уставка' },
                    { name: 'TE_5Y2', title: 'Температура' },
                    { name: 'Position_5Y2', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 276, y: 570, w: 74, h: 74 },
          p: { x: 365, y: 340, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '1M2',
              parent: 'FloorView',
              values: [
                { target: 'mode', tag: 'Pumps_1M2_FL_STS', type: 'control3', cmd: 'Pumps_1M2_FL_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>
