<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'Ahu2View',
  extends: BaseView,

  data () {
    return {
      layout: '07_AHU2',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 750, y: 350 }, p: { x: 570, y: 170 }, align: 'start', tag: 'AHU2_Sp', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 1100, y: 350 }, p: { x: 835, y: 170 }, align: 'end', tag: 'TE5a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 555, y: 685 }, p: { x: 490, y: 487 }, align: 'start', tag: 'TE4a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 615, y: 565 }, p: { x: 550, y: 370 }, align: 'middle', tag: 'Position_27Y3', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 580, y: 570, w: 68, h: 12 }, p: { x: 515, y: 377, w: 68, h: 12 }, max: 100, tag: 'Position_27Y3' }
      ],
      rotations: [
        { l: { x: 350, y: 380 }, p: { x: 493, y: 200 }, size: 94, angle: 0, speed: 1, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'fan_cw_94' },
        { l: { x: 695, y: 653 }, p: { x: 580, y: 456 }, size: 74, angle: 0, speed: 1, tag: 'AHU2_STS', type: 'bit', options: { pos: 7 }, el: 'pump_74' }
      ],
      dampers: [
        { l: { x: 28, y: 372 }, p: { x: 292, y: 188 }, size: 114, angle: 0, step: 90, shift: 0, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'damper_114' },
        { l: { x: 980, y: 372 }, p: { x: 716, y: 188 }, size: 114, angle: 0, step: 90, shift: 0, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'damper_114' }
      ],
      flutters: [
        { l: { x: 160, y: 373 }, p: { x: 419, y: 190 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 400 }, p: { x: 419, y: 217 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 427 }, p: { x: 419, y: 244 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 454 }, p: { x: 419, y: 271 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 336, y: 368, w: 120, h: 120 },
          p: { x: 480, y: 186, w: 120, h: 120 },
          data: {
            type: 'FaceplateSpOnOff',
            props: {
              name: 'AHU2',
              parent: 'Ahu2View',
              values: [
                { target: 'te', tag: 'TE5a', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU2_Sp', type: 'short', cmd: 'AHU2_Sp', cmd_type: 'setpoint' },
                { target: 'mode', tag: 'AHU2_STS', type: 'bit', cmd: 'AHU2_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ2 - 27Y3',
                  parent: 'Ahu2View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU2_Sp', title: 'Уставка' },
                    { name: 'TE5a', title: 'Температура' },
                    { name: 'Position_27Y3', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 750, y: 336, w: 45, h: 15 },
          p: { x: 568, y: 155, w: 45, h: 15 },
          data: {
            type: 'FaceplateSpOnOff',
            props: {
              name: 'AHU2',
              parent: 'Ahu2View',
              values: [
                { target: 'te', tag: 'TE5a', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU2_Sp', type: 'short', cmd: 'AHU2_Sp', cmd_type: 'setpoint' },
                { target: 'mode', tag: 'AHU2_STS', type: 'bit', cmd: 'AHU2_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ2 - 27Y3',
                  parent: 'Ahu2View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU2_Sp', title: 'Уставка' },
                    { name: 'TE5a', title: 'Температура' },
                    { name: 'Position_27Y3', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        }
      ]
    }
  }
}
</script>
