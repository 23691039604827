<template>
  <div>
    <svg id="main_landscape" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 220 1120 560">
      <!-- LAYOUT & HEADER -->
      <use :xlink:href="require(`@/assets/${layout}.svg`) + '#main_landscape'"/>
      <use xlink:href="../assets/HEADER.svg#main_landscape"/>
      <!-- PULSE -->
      <use :class="{invisible: true, visible: isDataReliable & pulseTick}" x="1089" y="228" xlink:href="../assets/common.svg#pulse"/>
      <!-- STATIC -->
      <use v-for="stat in getStatic" :key="'stat_' + stat.l.x + stat.l.y" :x="stat.l.x" :y="stat.l.y" :xlink:href="require('@/assets/common.svg') + `#${stat.el}`"/>
      <!-- ALARMS -->
      <circle :class="{ alarm: true, visible: getValue(alm) > 0 }" v-for="alm in getAlarms" :key="'alm_' + alm.tag" :cx="alm.l.x" :cy="alm.l.y" :r="alm.l.r" />
      <!-- STATUSES -->
      <rect :class="{ transparent: true, active: getValue(sts) > 0 }" v-for="sts in getStatuses" :key="'sts_' + sts.tag" :x="sts.l.x" :y="sts.l.y" :width="sts.l.w" :height="sts.l.h"/>
      <!-- VALUES -->
      <text :class="{value: true}" v-for="txt in getValues" :key="'txt_' + txt.tag" :x="txt.l.x" :y="txt.l.y" :text-anchor="txt.align">{{ getValue(txt) }}</text>
      <!-- LEVELS -->
      <rect :class="{level: true}" v-for="lvl in getLevels" :key="'lvl_' + lvl.tag" :y="lvl.l.y" :x="lvl.l.x" :width="Number(lvl.l.w * checkValue(getValue(lvl), 0) / lvl.max).toFixed(0)" :height="lvl.l.h"></rect>
      <!-- VALVES -->
      <circle :class="{transparent: true, open: getValue(vlv).active, close: getValue(vlv).inactive}" v-for="vlv in getValves" :key="'vlv_' + vlv.tag" :cx="vlv.l.x" :cy="vlv.l.y" :r="vlv.l.r"></circle>
      <text :class="{label: true, 'text-medium': true, auto: getValue(lbl).auto, manual: getValue(lbl).manual}" v-for="lbl in getValves" :key="'lbl_' + lbl.tag" :x="lbl.l.x - 2 * lbl.l.r" :y="lbl.l.y - lbl.l.r">{{ getValue(lbl).auto ? 'А' : 'Д' }}</text>
      <!-- ROTATIONS -->
      <use v-for="rot in getRotations" :key="'rot_' + rot.tag + rot.l.x + rot.l.y" :x="rot.l.x" :y="rot.l.y" :transform="getRotationTransformEx(rot.l, rot.size, rot.angle)" :xlink:href="require('@/assets/common.svg') + `#${rot.el}`"/>
      <text :class="{label: true, 'text-medium': true, auto: getValue(lbl).auto, manual: getValue(lbl).manual}" v-for="lbl in getRotations" :key="'lbl_' + lbl.tag + lbl.l.x + lbl.l.y" :x="lbl.l.x + checkValue(lbl.l.cx, 0)" :y="lbl.l.y + checkValue(lbl.l.cy, 0)">{{ getValue(lbl).auto ? 'А' : 'Д' }}</text>
      <!-- ROLLINGS -->
      <use v-for="rol in getRollings" :key="'rol_' + rol.tag + rol.l.x + rol.l.y" :x="rol.l.x + getDeltaX(rol.angle, rol.r)" :y="rol.l.y + getDeltaY(rol.angle, rol.r)" :transform="getRollingTransform(rol.l, rol.tilt, rol.angle, rol.cx, rol.cy, rol.r)" :xlink:href="require('@/assets/common.svg') + `#${rol.el}`"/>
      <!-- STROKINGS -->
      <use v-for="str in getStrokings" :key="'str_' + str.tag + str.l.x + str.l.y" :x="str.l.x" :y="str.l.y + getDeltaY(str.angle, str.r)" :xlink:href="require('@/assets/common.svg') + `#${str.el}`"/>
      <!-- DAMPERS -->
      <use v-for="dmp in getDampers" :key="'dmp_' + dmp.tag + dmp.l.x + dmp.l.y" :x="dmp.l.x" :y="dmp.l.y" :transform="getRotationTransformEx(dmp.l, dmp.size, dmp.angle)" :xlink:href="require('@/assets/common.svg') + `#${dmp.el}`"/>
      <!-- FLUTTERS -->
      <use v-for="flt in getFlutters" :key="'flt_' + flt.tag + flt.l.x + flt.l.y" :x="flt.l.x" :y="flt.l.y" :transform="getFlutterTransform(flt.l, flt.cx, flt.cy, flt.angle)" :xlink:href="require('@/assets/common.svg') + `#${flt.el}`"/>
      <!-- MULTISTATES -->
      <g v-for="mst in getMultistates" :key="'mst_' + mst.tag + mst.l.x + mst.l.y">
        <use :x="mst.l.x" :y="mst.l.y" :xlink:href="require('@/assets/common.svg') + `#${mst.el}${getMultistateSuffix(mst.el, getValue(mst))}`"/>
        <text :class="{ label: true, 'text-medium': true, auto: getValue(mst).auto, manual: getValue(mst).manual}" :x="mst.l.x + mst.cx" :y="mst.l.y + mst.cy">{{ getValue(mst).auto === true ? 'А' : '' }}{{ getValue(mst).auto === false ? 'Д' : '' }}</text>
      </g>
      <!-- NAVIGATION -->
      <rect :class="{button: true}" v-for="nav in getNavigation" :key="nav.target" :x="nav.l.x" :y="nav.l.y" :width="nav.l.w" :height="nav.l.h" @click="$emit('navigate', nav.target)" />
      <!-- FACEPLATES -->
      <rect :class="{ transparent: true }" v-for="fpl in getFaceplates" :key="'fpl_' + fpl.data.props.name + fpl.l.x + fpl.l.y" :x="fpl.l.x" :y="fpl.l.y" :width="fpl.l.w" :height="fpl.l.h" @click="$emit('createView', fpl.data)" />
    </svg>
    <svg id="main_portrait" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="280 0 560 1000">
      <!-- LAYOUT & HEADER -->
      <use :xlink:href="require(`@/assets/${layout}.svg`) + '#main_portrait'"/>
      <use xlink:href="../assets/HEADER.svg#main_portrait"/>
      <!-- PULSE -->
      <use :class="{invisible: true, visible: isDataReliable & pulseTick}" x="809" y="8" xlink:href="../assets/common.svg#pulse"/>
      <!-- STATIC -->
      <use v-for="stat in getStatic" :key="'stat_' + stat.p.x + stat.p.y" :x="stat.p.x" :y="stat.p.y" :xlink:href="require('@/assets/common.svg') + `#${stat.el}`"/>
      <!-- ALARMS -->
      <circle :class="{ alarm: true, visible: getValue(alm) > 0 }" v-for="alm in getAlarms" :key="'alm_' + alm.tag" :cx="alm.p.x" :cy="alm.p.y" :r="alm.p.r" />
      <!-- STATUSES -->
      <rect :class="{ transparent: true, active: getValue(sts) > 0 }" v-for="sts in getStatuses" :key="'sts_' + sts.tag" :x="sts.p.x" :y="sts.p.y" :width="sts.p.w" :height="sts.p.h"/>
      <!-- VALUES -->
      <text :class="{value: true}" v-for="txt in getValues" :key="'txt_' + txt.tag" :x="txt.p.x" :y="txt.p.y" :text-anchor="txt.align">{{ getValue(txt) }}</text>
      <!-- LEVELS -->
      <rect :class="{level: true}" v-for="lvl in getLevels" :key="'lvl_' + lvl.tag" :y="lvl.p.y" :x="lvl.p.x" :width="Number(lvl.p.w * checkValue(getValue(lvl), 0) / lvl.max).toFixed(0)" :height="lvl.p.h"></rect>
      <!-- VALVES -->
      <circle :class="{transparent: true, open: getValue(vlv).active, close: getValue(vlv).inactive}" v-for="vlv in getValves" :key="'vlv_' + vlv.tag" :cx="vlv.p.x" :cy="vlv.p.y" :r="vlv.p.r"></circle>
      <text :class="{label: true, 'text-medium': true, auto: getValue(lbl).auto, manual: getValue(lbl).manual}" v-for="lbl in getValves" :key="'lbl_' + lbl.tag" :x="lbl.p.x - 2 * lbl.p.r" :y="lbl.p.y - lbl.p.r">{{ getValue(lbl).auto ? 'А' : 'Д' }}</text>
      <!-- ROTATIONS -->
      <use v-for="rot in getRotations" :key="'rot_' + rot.tag + rot.p.x + rot.p.y" :x="rot.p.x" :y="rot.p.y" :transform="getRotationTransformEx(rot.p, rot.size, rot.angle)" :xlink:href="require('@/assets/common.svg') + `#${rot.el}`"/>
      <text :class="{label: true, 'text-medium': true, auto: getValue(lbl).auto, manual: getValue(lbl).manual}" v-for="lbl in getRotations" :key="'lbl_' + lbl.tag + lbl.p.x + lbl.p.y" :x="lbl.p.x" :y="lbl.p.y">{{ getValue(lbl).auto ? 'А' : 'Д' }}</text>
      <!-- ROLLINGS -->
      <use v-for="rol in getRollings" :key="'rol_' + rol.tag + rol.p.x + rol.p.y" :x="rol.p.x + getDeltaX(rol.angle, rol.r)" :y="rol.p.y + getDeltaY(rol.angle, rol.r)" :transform="getRollingTransform(rol.p, rol.tilt, rol.angle, rol.cx, rol.cy, rol.r)" :xlink:href="require('@/assets/common.svg') + `#${rol.el}`"/>
      <!-- STROKINGS -->
      <use v-for="str in getStrokings" :key="'str_' + str.tag + str.p.x + str.p.y" :x="str.p.x" :y="str.p.y + getDeltaY(str.angle, str.r)" :xlink:href="require('@/assets/common.svg') + `#${str.el}`"/>
      <!-- DAMPERS -->
      <use v-for="dmp in getDampers" :key="'dmp_' + dmp.tag + dmp.p.x + dmp.p.y" :x="dmp.p.x" :y="dmp.p.y" :transform="getRotationTransformEx(dmp.p, dmp.size, dmp.angle)" :xlink:href="require('@/assets/common.svg') + `#${dmp.el}`"/>
      <!-- FLUTTERS -->
      <use v-for="flt in getFlutters" :key="'flt_' + flt.tag + flt.p.x + flt.p.y" :x="flt.p.x" :y="flt.p.y" :transform="getFlutterTransform(flt.p, flt.cx, flt.cy, flt.angle)" :xlink:href="require('@/assets/common.svg') + `#${flt.el}`"/>
      <!-- MULTISTATES -->
      <g v-for="mst in getMultistates" :key="'mst_' + mst.tag + mst.p.x + mst.p.y">
        <use :x="mst.p.x" :y="mst.p.y" :xlink:href="require('@/assets/common.svg') + `#${mst.el}${getMultistateSuffix(mst.el, getValue(mst))}`"/>
        <text :class="{ label: true, 'text-medium': true, auto: getValue(mst).auto, manual: getValue(mst).manual}" :x="mst.p.x + mst.cx" :y="mst.p.y + mst.cy">{{ getValue(mst).auto === true ? 'А' : '' }}{{ getValue(mst).auto === false ? 'Д' : '' }}</text>
      </g>
      <!-- NAVIGATION -->
      <rect :class="{button: true}" v-for="nav in getNavigation" :key="nav.target" :x="nav.p.x" :y="nav.p.y" :width="nav.p.w" :height="nav.p.h" @click="$emit('navigate', nav.target)" />
      <!-- FACEPLATES -->
      <rect :class="{ transparent: true }" v-for="fpl in getFaceplates" :key="'fpl_' + fpl.data.props.name + fpl.p.x + fpl.p.y" :x="fpl.p.x" :y="fpl.p.y" :width="fpl.p.w" :height="fpl.p.h" @click="$emit('createView', fpl.data)" />
    </svg>
    <div id="loader">
      <div ref="infinitescrolltrigger" id="scoll-trigger"></div>
      <div class="circle-loader" v-if="!isDataReliable"></div>
    </div>
  </div>
</template>

<script>
import { Main } from '../mixins/main'

export default {
  name: 'BaseView',
  mixins: [
    Main
  ]
}
</script>
