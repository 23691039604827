<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'Ahu4View',
  extends: BaseView,

  data () {
    return {
      layout: '04_AHU4',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 170, y: 314.25 }, p: { x: 465, y: 95 }, align: 'start', tag: 'AHU4_Sp', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 502, y: 487 }, p: { x: 500, y: 330 }, align: 'end', tag: 'recup4_Sp', type: 'short', options: { precision: 1 }, postfix: ' %' },
        { l: { x: 1058, y: 375 }, p: { x: 771, y: 115 }, align: 'middle', tag: 'AHU4_TE2', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 1058, y: 510 }, p: { x: 807, y: 495 }, align: 'middle', tag: 'AHU4_TE1', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 626, y: 734 }, p: { x: 505, y: 562 }, align: 'start', tag: 'AHU4_TE3', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 684, y: 642 }, p: { x: 563, y: 470 }, align: 'middle', tag: 'AHU4_HWV', type: 'short', options: { precision: 1 }, postfix: ' %' },
        { l: { x: 1008, y: 642 }, p: { x: 658, y: 655 }, align: 'middle', tag: 'AHU4_CWV', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 651, y: 647.5, w: 68, h: 12 }, p: { x: 528, y: 475, w: 68, h: 12 }, max: 100, tag: 'AHU4_HWV' },
        { l: { x: 974, y: 648, w: 68, h: 12 }, p: { x: 624, y: 660, w: 68, h: 12 }, max: 100, tag: 'AHU4_CWV' }
      ],
      rotations: [
        { l: { x: 286, y: 367.5 }, p: { x: 419, y: 196 }, size: 94, angle: 0, speed: 1, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'fan_cw_94' },
        { l: { x: 195, y: 503 }, p: { x: 306, y: 331 }, size: 94, angle: 0, speed: 1, reverse: true, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'fan_ccw_94' },
        { l: { x: 740, y: 704 }, p: { x: 620, y: 532 }, size: 74, angle: 0, speed: 1, tag: 'AHU4_STS', type: 'bit', options: { pos: 7 }, el: 'pump_74' }
      ],
      dampers: [
        { l: { x: 101.5, y: 350 }, p: { x: 288, y: 177 }, size: 64, angle: 0, step: 90, shift: 0, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'damper_64' },
        { l: { x: 101.5, y: 415 }, p: { x: 288, y: 242 }, size: 64, angle: 0, step: 90, shift: 0, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'damper_64' },
        { l: { x: 955, y: 350 }, p: { x: 763, y: 177 }, size: 64, angle: 0, step: 90, shift: 0, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'damper_64' },
        { l: { x: 955, y: 415 }, p: { x: 763, y: 242 }, size: 64, angle: 0, step: 90, shift: 0, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'damper_64' },
        { l: { x: 505, y: 422 }, p: { x: 502, y: 255 }, size: 114, angle: 0, step: 1, shift: 130, tag: 'recup4_Sp', type: 'short', el: 'damper_114' }
      ],
      flutters: [
        { l: { x: 192, y: 360 }, p: { x: 361, y: 185 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 192, y: 387 }, p: { x: 361, y: 212 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 192, y: 414 }, p: { x: 361, y: 239 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 192, y: 441 }, p: { x: 361, y: 266 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 881, y: 360 }, p: { x: 702, y: 185 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 881, y: 387 }, p: { x: 702, y: 212 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 881, y: 414 }, p: { x: 702, y: 239 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 881, y: 441 }, p: { x: 702, y: 266 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 505, y: 420, w: 115, h: 115 },
          p: { x: 501, y: 255, w: 115, h: 115 },
          data: {
            type: 'FaceplateAhu',
            props: {
              name: 'AHU4',
              parent: 'Ahu4View',
              values: [
                { target: 'te', tag: 'AHU4_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU4_Sp', type: 'short', cmd: 'AHU4_Sp', cmd_type: 'value' },
                { target: 'pos_sp', tag: 'recup4_Sp', type: 'short', cmd: 'recup4_Sp', cmd_type: 'value' },
                { target: 'mode', tag: 'AHU4_STS', type: 'bit', cmd: 'AHU4_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ4 - HWV',
                  parent: 'Ahu4View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU4_Sp', title: 'Уставка' },
                    { name: 'AHU4_TE1', title: 'Температура' },
                    { name: 'AHU4_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 170, y: 300, w: 45, h: 15 },
          p: { x: 464, y: 81, w: 45, h: 15 },
          data: {
            type: 'FaceplateAhu',
            props: {
              name: 'AHU4',
              parent: 'Ahu4View',
              values: [
                { target: 'te', tag: 'AHU4_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU4_Sp', type: 'short', cmd: 'AHU4_Sp', cmd_type: 'value' },
                { target: 'pos_sp', tag: 'recup4_Sp', type: 'short', cmd: 'recup4_Sp', cmd_type: 'value' },
                { target: 'mode', tag: 'AHU4_STS', type: 'bit', cmd: 'AHU4_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ4 - HWV',
                  parent: 'Ahu4View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU4_Sp', title: 'Уставка' },
                    { name: 'AHU4_TE1', title: 'Температура' },
                    { name: 'AHU4_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 460, y: 474, w: 45, h: 15 },
          p: { x: 462, y: 313, w: 45, h: 15 },
          data: {
            type: 'FaceplateAhu',
            props: {
              name: 'AHU4',
              parent: 'Ahu4View',
              values: [
                { target: 'te', tag: 'AHU4_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU4_Sp', type: 'short', cmd: 'AHU4_Sp', cmd_type: 'value' },
                { target: 'pos_sp', tag: 'recup4_Sp', type: 'short', cmd: 'recup4_Sp', cmd_type: 'value' },
                { target: 'mode', tag: 'AHU4_STS', type: 'bit', cmd: 'AHU4_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ4 - HWV',
                  parent: 'Ahu4View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU4_Sp', title: 'Уставка' },
                    { name: 'AHU4_TE1', title: 'Температура' },
                    { name: 'AHU4_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        }
      ]
    }
  }
}
</script>
