export const Animation = {
  methods: {
    getRotationTransform (element) {
      return `rotate(${element.angle} ${element.x + element.size / 2} ${element.y + element.size / 2})`
    },
    getRotationTransformEx (location, size, angle) {
      return `rotate(${angle} ${location.x + size / 2} ${location.y + size / 2})`
    },
    getRollingTransform (location, tilt, angle, cx, cy, r) {
      return `rotate(${0 - tilt * Math.cos(angle * Math.PI / 180)}, ${location.x + cx + this.getDeltaX(angle, r)}, ${location.y + cy + this.getDeltaY(angle, r)})`
    },
    getFlutterTransform (location, cx, cy, angle) {
      return `rotate(${angle} ${location.x + cx} ${location.y + cy})`
    },
    getDeltaY (angle, r) {
      return Math.sin(angle * Math.PI / 180) * r + r
    },
    getDeltaX (angle, r) {
      return Math.cos(angle * Math.PI / 180) * r
    },
    getPulseTick () {
      const ms = Date.now() % 1000
      // return ms <= 200 || (ms > 300 && ms < 500)
      return ms <= 500
    }
  }
}
