<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'VentView',
  extends: BaseView,

  data () {
    return {
      layout: '10_VENT',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 688, y: 570 }, p: { x: 453, y: 785 }, align: 'middle', tag: 'TE9a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 590, y: 410 }, p: { x: 750, y: 495 }, align: 'middle', tag: 'TE10a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 252, y: 350 }, p: { x: 575, y: 105 }, align: 'middle', tag: 'Position_26Y2', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 220, y: 355, w: 68, h: 12 }, p: { x: 543, y: 110, w: 68, h: 12 }, max: 100, tag: 'Position_26Y2' }
      ],
      valves: [
        { l: { x: 254, y: 389, r: 9 }, p: { x: 577, y: 152, r: 9 }, tag: 'REG_26Y2_STS', type: 'control2' }
      ],
      rotations: [
        { l: { x: 850, y: 494 }, p: { x: 572, y: 720 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_7M3_VNT_STS', type: 'control3', el: 'pump_74' },
        { l: { x: 850, y: 650 }, p: { x: 572, y: 875 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_7M4_VNT_STS', type: 'control3', el: 'pump_74' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 232, y: 378, w: 45, h: 50 },
          p: { x: 555, y: 142, w: 45, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'Вентиляция 26Y2',
              parent: 'VentView',
              values: [
                { target: 'mode', tag: 'REG_26Y2_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE9a', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_26Y2', type: 'short', cmd: 'SP_26Y2', cmd_type: 'value' },
                { target: 'out', tag: 'Position_26Y2', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_26Y2', type: 'short', cmd: 'Manual_26Y2', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'Вентиляция 26Y2',
                  parent: 'VentView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_26Y2', title: 'Уставка' },
                    { name: 'TE_26Y2', title: 'Температура' },
                    { name: 'Position_26Y2', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 850, y: 494, w: 74, h: 74 },
          p: { x: 572, y: 720, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '7M3',
              parent: 'VentView',
              values: [
                { target: 'mode', tag: 'Pumps_7M3_VNT_STS', type: 'control3', cmd: 'Pumps_7M3_VNT_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 850, y: 650, w: 74, h: 74 },
          p: { x: 572, y: 875, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '7M4',
              parent: 'VentView',
              values: [
                { target: 'mode', tag: 'Pumps_7M4_VNT_STS', type: 'control3', cmd: 'Pumps_7M4_VNT_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>
