<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'GvsView',
  extends: BaseView,

  data () {
    return {
      layout: '11_GVS',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.25 }, p: { x: 495, y: 65.25 }, align: 'end', postfix: ' °C', tag: 'TE_0', type: 'short', options: { precision: 1 } },
        { l: { x: 870, y: 496 }, p: { x: 732, y: 745 }, align: 'start', postfix: '°C', tag: 'TE12a', type: 'short', options: { precision: 1 } },
        { l: { x: 142, y: 570 }, p: { x: 390, y: 570 }, align: 'middle', postfix: ' °C', tag: 'TE14a', type: 'short', options: { precision: 1 } },
        { l: { x: 597, y: 510 }, p: { x: 704, y: 300 }, align: 'middle', postfix: ' °C', tag: 'TE13a', type: 'short', options: { precision: 1 } },
        { l: { x: 330, y: 364 }, p: { x: 378, y: 128.25 }, align: 'start', postfix: ' °C', tag: 'SP_26Y3', type: 'short', options: { precision: 1 } },
        { l: { x: 489, y: 303 }, p: { x: 459, y: 252 }, align: 'middle', postfix: ' %', tag: 'Position_27Y4', type: 'short', options: { precision: 1 } },
        { l: { x: 1062, y: 548 }, p: { x: 755, y: 817 }, align: 'middle', postfix: ' %', tag: 'Position_26Y3', type: 'short', options: { precision: 1 } },
        { l: { x: 555, y: 639 }, p: { x: 727, y: 498 }, align: 'end', postfix: ' %', tag: 'Position_20Y1', type: 'short', options: { precision: 1 } }
      ],
      levels: [
        { l: { x: 452, y: 307, w: 68, h: 12 }, p: { x: 423, y: 256, w: 68, h: 12 }, max: 100, tag: 'Position_27Y4' },
        { l: { x: 1030, y: 552, w: 68, h: 12 }, p: { x: 720, y: 821, w: 68, h: 12 }, max: 100, tag: 'Position_26Y3' },
        { l: { x: 562, y: 626.5, w: 68, h: 12 }, p: { x: 673, y: 469, w: 68, h: 12 }, max: 100, tag: 'Position_20Y1' }
      ],
      valves: [
        { l: { x: 597, y: 660.75, r: 9 }, p: { x: 705, y: 418.25, r: 9 }, tag: 'REG_20Y1_STS', type: 'control2' },
        { l: { x: 1063.5, y: 588.25, r: 9 }, p: { x: 753.5, y: 856.25, r: 9 }, tag: 'REG_26Y3_STS', type: 'control2' },
        { l: { x: 486, y: 343, r: 9 }, p: { x: 457, y: 288.5, r: 9 }, tag: 'REG_27Y4_STS', type: 'control2' }
      ],
      rotations: [
        { l: { x: 748, y: 577 }, p: { x: 439, y: 800.5 }, size: 50, angle: 0, speed: 1, tag: 'Pumps_11M1_GVS_STS', type: 'control3', el: 'pump_50' },
        { l: { x: 750, y: 657 }, p: { x: 439, y: 883 }, size: 50, angle: 0, speed: 1, tag: 'Pumps_11M2_GVS_STS', type: 'control3', el: 'pump_50' },
        { l: { x: 118, y: 438 }, p: { x: 366, y: 434 }, size: 50, angle: 0, speed: 1, tag: 'Pumps_11M3_GVS_STS', type: 'control3', el: 'pump_50' },
        { l: { x: 853, y: 395 }, p: { x: 572, y: 178.5 }, size: 50, angle: 0, speed: 1, tag: 'Pumps_11M4_GVS_STS', type: 'control3', el: 'pump_50' },
        { l: { x: 638, y: 395 }, p: { x: 356, y: 178.5 }, size: 50, angle: 0, speed: 1, tag: 'Pumps_11M5_GVS_STS', type: 'control3', el: 'pump_50' }
      ],
      statuses: [
        { l: { x: 664, y: 512, w: 49, h: 26.5 }, p: { x: 756, y: 276, w: 59, h: 27 }, tag: 'BOILER_STS', type: 'short' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 572, y: 650, w: 50, h: 50 },
          p: { x: 680, y: 406, w: 50, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'ГВС 20Y1',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'REG_20Y1_STS', type: 'control2', cmd_type: 'control4_variant' },
                { target: 'te_pv', tag: 'TE_20Y1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_20Y1', type: 'short', cmd: 'SP_20Y1', cmd_type: 'value' },
                { target: 'out', tag: 'Position_20Y1', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_20Y1', type: 'short', cmd: 'Manual_20Y1', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'ГВС 20Y1',
                  parent: 'GvsView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_20Y1', title: 'Уставка' },
                    { name: 'TE_20Y1', title: 'Температура' },
                    { name: 'Position_20Y1', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 461, y: 332, w: 50, h: 50 },
          p: { x: 432, y: 276, w: 50, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'ГВС 27Y4',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'REG_27Y4_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_27Y4', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_27Y4', type: 'short', cmd: 'SP_27Y4', cmd_type: 'value' },
                { target: 'out', tag: 'Position_27Y4', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_27Y4', type: 'short', cmd: 'Manual_27Y4', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'ГВС 27Y4',
                  parent: 'GvsView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_27Y4', title: 'Уставка' },
                    { name: 'TE_27Y4', title: 'Температура' },
                    { name: 'Position_27Y4', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 1040, y: 577, w: 50, h: 50 },
          p: { x: 728, y: 845, w: 50, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'ГВС 26Y3',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'REG_26Y3_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_26Y3', type: 'short', cmd: 'SP_26Y3', cmd_type: 'value' },
                { target: 'out', tag: 'Position_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_26Y3', type: 'short', cmd: 'Manual_26Y3', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'ГВС 26Y3',
                  parent: 'GvsView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_26Y3', title: 'Уставка' },
                    { name: 'TE_26Y3', title: 'Температура' },
                    { name: 'Position_26Y3', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 330, y: 350, w: 50, h: 20 },
          p: { x: 380, y: 110, w: 50, h: 20 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'ГВС 26Y3',
              parent: 'GvsView',
              tags: [
                { name: 'REG_26Y3_STS', cmd: 'REG_26Y3_CMD', value: undefined },
                { name: 'TE_26Y3', value: undefined },
                { name: 'SP_26Y3', cmd: 'SP_26Y3', value: undefined },
                { name: 'Position_26Y3', value: undefined },
                { name: 'Manual_26Y3', cmd: 'Manual_26Y3', value: undefined }
              ],
              values: [
                { target: 'mode', tag: 'REG_26Y3_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_26Y3', type: 'short' },
                { target: 'out', tag: 'Position_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'manual', tag: 'Manual_26Y3', type: 'short' }
              ]
            }
          }
        },
        {
          l: { x: 748, y: 577, w: 50, h: 50 },
          p: { x: 438, y: 801, w: 50, h: 50 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M1',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'Pumps_11M1_GVS_STS', type: 'control3', cmd: 'Pumps_11M1_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 750, y: 657, w: 50, h: 50 },
          p: { x: 439, y: 884, w: 50, h: 50 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M2',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'Pumps_11M2_GVS_STS', type: 'control3', cmd: 'Pumps_11M2_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 118, y: 438, w: 50, h: 50 },
          p: { x: 366, y: 434, w: 50, h: 50 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M3',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'Pumps_11M3_GVS_STS', type: 'control3', cmd: 'Pumps_11M3_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 853, y: 395, w: 50, h: 50 },
          p: { x: 572, y: 179, w: 50, h: 50 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M4',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'Pumps_11M4_GVS_STS', type: 'control3', cmd: 'Pumps_11M4_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 638, y: 395, w: 50, h: 50 },
          p: { x: 356, y: 179, w: 50, h: 50 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M5',
              parent: 'GvsView',
              values: [
                { target: 'mode', tag: 'Pumps_11M5_GVS_STS', type: 'control3', cmd: 'Pumps_11M5_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
