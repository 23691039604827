<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'MixView',
  extends: BaseView,

  data () {
    return {
      layout: '08_MIX',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' }
      ],
      alarms: [
        { l: { x: 244, y: 419 }, p: { x: 482, y: 218 }, r: 8, tag: 'AHU11_ALM', type: 'short' },
        { l: { x: 533, y: 419 }, p: { x: 826, y: 218 }, r: 8, tag: 'AHU12_ALM', type: 'short' },
        { l: { x: 822, y: 419 }, p: { x: 482, y: 624 }, r: 8, tag: 'AHU14_15_ALM', type: 'short' },
        { l: { x: 1110, y: 419 }, p: { x: 826, y: 624 }, r: 8, tag: 'AHU17_ALM', type: 'short' }
      ],
      rotations: [
        { l: { x: 82, y: 454, cx: -5, cy: -5 }, p: { x: 341, y: 234 }, size: 94, angle: 0, speed: 1, reverse: false, tag: 'AHU11_STS', type: 'control3', el: 'fan_cw_94' },
        { l: { x: 371, y: 454, cx: -5, cy: -5 }, p: { x: 686, y: 234 }, size: 94, angle: 0, speed: 1, reverse: false, tag: 'AHU12_STS', type: 'control3', el: 'fan_cw_94' },
        { l: { x: 660, y: 454, cx: -5, cy: -5 }, p: { x: 342, y: 640 }, size: 94, angle: 0, speed: 1, reverse: false, tag: 'AHU14_15_STS', type: 'control3', el: 'fan_cw_94' },
        { l: { x: 944, y: 456, cx: -5, cy: -5 }, p: { x: 686, y: 640 }, size: 94, angle: 0, speed: 1, reverse: false, tag: 'AHU17_STS', type: 'control3', el: 'fan_cw_94' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 0, y: 405, w: 260, h: 194 },
          p: { x: 280, y: 200, w: 220, h: 160 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: 'AHU11',
              parent: 'MixView',
              values: [
                { target: 'mode', tag: 'AHU11_STS', type: 'control3', cmd: 'AHU11_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 290, y: 405, w: 260, h: 194 },
          p: { x: 620, y: 200, w: 220, h: 160 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: 'AHU12',
              parent: 'MixView',
              values: [
                { target: 'mode', tag: 'AHU12_STS', type: 'control3', cmd: 'AHU12_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 580, y: 405, w: 260, h: 194 },
          p: { x: 280, y: 610, w: 220, h: 160 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: 'AHU14_15',
              parent: 'MixView',
              values: [
                { target: 'mode', tag: 'AHU14_15_STS', type: 'control3', cmd: 'AHU14_15_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 865, y: 405, w: 260, h: 194 },
          p: { x: 620, y: 610, w: 220, h: 160 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: 'AHU17',
              parent: 'MixView',
              values: [
                { target: 'mode', tag: 'AHU17_STS', type: 'control3', cmd: 'AHU17_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .active{
    fill: #00ff0030
  }
</style>
