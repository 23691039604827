<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'Ahu18View',
  extends: BaseView,

  data () {
    return {
      layout: '05_AHU18',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 820, y: 312.25 }, p: { x: 565, y: 116 }, align: 'start', tag: 'AHU18_Sp', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 560, y: 740 }, p: { x: 550, y: 575 }, align: 'start', tag: 'AHU18_TE3', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 100, y: 345 }, p: { x: 330, y: 150 }, align: 'start', tag: 'AHU18_TE2', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 1040, y: 510 }, p: { x: 792, y: 289 }, align: 'middle', tag: 'AHU18_TE1', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 618, y: 648 }, p: { x: 610, y: 485 }, align: 'middle', tag: 'AHU18_HWV', type: 'short', options: { precision: 1 }, postfix: ' %' },
        { l: { x: 878, y: 648 }, p: { x: 805, y: 485 }, align: 'middle', tag: 'AHU18_CWV', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 583, y: 651, w: 68, h: 12 }, p: { x: 575, y: 490, w: 68, h: 12 }, max: 100, tag: 'AHU18_HWV' },
        { l: { x: 843, y: 651, w: 68, h: 12 }, p: { x: 772, y: 490, w: 68, h: 12 }, max: 100, tag: 'AHU18_CWV' }
      ],
      rotations: [
        { l: { x: 249, y: 509 }, p: { x: 508, y: 315 }, size: 94, angle: 0, speed: 1, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'fan_cw_94' },
        { l: { x: 774, y: 345 }, p: { x: 499, y: 149 }, size: 94, angle: 0, speed: 1, reverse: true, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'fan_ccw_94' },
        { l: { x: 644, y: 705 }, p: { x: 635, y: 544 }, size: 74, angle: 0, speed: 1, tag: 'AHU18_STS', type: 'bit', options: { pos: 7 }, el: 'pump_74' }
      ],
      dampers: [
        { l: { x: 11, y: 500 }, p: { x: 292, y: 306 }, size: 114, angle: 0, step: 90, shift: 0, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'damper_114' },
        { l: { x: 992, y: 337 }, p: { x: 712, y: 140 }, size: 114, angle: 0, step: 90, shift: 0, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'damper_114' }
      ],
      flutters: [
        { l: { x: 160, y: 503 }, p: { x: 429, y: 310 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 530 }, p: { x: 429, y: 337 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 557 }, p: { x: 429, y: 364 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 160, y: 584 }, p: { x: 429, y: 391 }, cx: 0, cy: 26, angle: 0, tilt: 30, drift: 3, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_right_48' },
        { l: { x: 916, y: 340 }, p: { x: 633, y: 145 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 916, y: 367 }, p: { x: 633, y: 172 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 916, y: 394 }, p: { x: 633, y: 199 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' },
        { l: { x: 916, y: 421 }, p: { x: 633, y: 226 }, cx: 48, cy: 26, angle: 0, tilt: 30, drift: 3, reverse: true, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 }, el: 'hood_left_48' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 762, y: 332, w: 120, h: 120 },
          p: { x: 485, y: 136, w: 120, h: 120 },
          data: {
            type: 'FaceplateSpOnOff',
            props: {
              name: 'AHU18',
              parent: 'Ahu18View',
              values: [
                { target: 'te', tag: 'AHU18_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU18_Sp', type: 'short', cmd: 'AHU18_Sp', cmd_type: 'setpoint' },
                { target: 'mode', tag: 'AHU18_STS', type: 'bit', cmd: 'AHU18_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ18 - HWV',
                  parent: 'Ahu18View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU18_Sp', title: 'Уставка' },
                    { name: 'AHU18_TE1', title: 'Температура' },
                    { name: 'AHU18_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 235, y: 495, w: 120, h: 120 },
          p: { x: 495, y: 301, w: 120, h: 120 },
          data: {
            type: 'FaceplateSpOnOff',
            props: {
              name: 'AHU18',
              parent: 'Ahu18View',
              values: [
                { target: 'te', tag: 'AHU18_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU18_Sp', type: 'short', cmd: 'AHU18_Sp', cmd_type: 'setpoint' },
                { target: 'mode', tag: 'AHU18_STS', type: 'bit', cmd: 'AHU18_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ18 - HWV',
                  parent: 'Ahu18View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU18_Sp', title: 'Уставка' },
                    { name: 'AHU18_TE1', title: 'Температура' },
                    { name: 'AHU18_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 820, y: 298, w: 45, h: 15 },
          p: { x: 568, y: 103, w: 45, h: 15 },
          data: {
            type: 'FaceplateSpOnOff',
            props: {
              name: 'AHU18',
              parent: 'Ahu18View',
              values: [
                { target: 'te', tag: 'AHU18_TE1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'AHU18_Sp', type: 'short', cmd: 'AHU18_Sp', cmd_type: 'setpoint' },
                { target: 'mode', tag: 'AHU18_STS', type: 'bit', cmd: 'AHU18_CMD', cmd_type: 'ahu18', options: { pos: 0 } }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'АХУ18 - HWV',
                  parent: 'Ahu18View',
                  gap: 86400,
                  tags: [
                    { name: 'AHU18_Sp', title: 'Уставка' },
                    { name: 'AHU18_TE1', title: 'Температура' },
                    { name: 'AHU18_HWV', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        }
      ]
    }
  }
}
</script>
