<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'HeatView',
  extends: BaseView,

  data () {
    return {
      layout: '09_HEAT',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'MainView' }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.75 }, p: { x: 495, y: 65.25 }, align: 'end', tag: 'TE_0', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 320, y: 380 }, p: { x: 580, y: 132 }, align: 'start', tag: 'SP_26Y1', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 819, y: 630 }, p: { x: 520, y: 898 }, align: 'start', tag: 'TE7a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 340, y: 420 }, p: { x: 562, y: 172 }, align: 'start', tag: 'TE8a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 197, y: 484 }, p: { x: 490, y: 235 }, align: 'middle', tag: 'TE12a', type: 'short', options: { precision: 1 }, postfix: ' °C' },
        { l: { x: 522, y: 320 }, p: { x: 675, y: 75 }, align: 'middle', tag: 'Position_26Y1', type: 'short', options: { precision: 1 }, postfix: ' %' },
        { l: { x: 495, y: 518 }, p: { x: 705, y: 170 }, align: 'middle', tag: 'Position_26Y3', type: 'short', options: { precision: 1 }, postfix: ' %' }
      ],
      levels: [
        { l: { x: 488, y: 323.5, w: 68, h: 12 }, p: { x: 642, y: 80, w: 68, h: 12 }, max: 100, tag: 'Position_26Y1' },
        { l: { x: 460, y: 523, w: 68, h: 12 }, p: { x: 673, y: 175, w: 68, h: 12 }, max: 100, tag: 'Position_26Y3' }
      ],
      valves: [
        { l: { x: 518, y: 471.5, r: 9 }, p: { x: 666, y: 218, r: 9 }, tag: 'REG_26Y3_STS', type: 'control2' },
        { l: { x: 524, y: 363, r: 9 }, p: { x: 672, y: 114, r: 9 }, tag: 'REG_26Y1_STS', type: 'control2' }
      ],
      rotations: [
        { l: { x: 152, y: 555 }, p: { x: 362, y: 302 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_11M1_GVS_STS', type: 'control3', el: 'pump_74' },
        { l: { x: 152, y: 672 }, p: { x: 362, y: 420 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_11M2_GVS_STS', type: 'control3', el: 'pump_74' },
        { l: { x: 925, y: 535 }, p: { x: 614, y: 786 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_7M1_OTO_STS', type: 'control3', el: 'pump_74' },
        { l: { x: 925, y: 652 }, p: { x: 614, y: 905 }, size: 74, angle: 0, speed: 1, tag: 'Pumps_7M2_OTO_STS', type: 'control3', el: 'pump_74' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 502, y: 350, w: 45, h: 50 },
          p: { x: 650, y: 102, w: 45, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'Отопление 26Y1',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'REG_26Y1_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_26Y1', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_26Y1', type: 'short', cmd: 'SP_26Y1', cmd_type: 'value' },
                { target: 'out', tag: 'Position_26Y1', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_26Y1', type: 'short', cmd: 'Manual_26Y1', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'Отопление 26Y1',
                  parent: 'HeatView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_26Y1', title: 'Уставка' },
                    { name: 'TE_26Y1', title: 'Температура' },
                    { name: 'Position_26Y1', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 478, y: 450, w: 50, h: 45 },
          p: { x: 632, y: 195, w: 45, h: 50 },
          data: {
            type: 'FaceplateValve',
            props: {
              name: 'Отопление 26Y3',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'REG_26Y3_STS', type: 'control2' },
                { target: 'te_pv', tag: 'TE_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_26Y3', type: 'short', cmd: 'SP_26Y3', cmd_type: 'value' },
                { target: 'out', tag: 'Position_26Y3', type: 'short', options: { precision: 1 } },
                { target: 'pos_sp', tag: 'Manual_26Y3', type: 'short', cmd: 'Manual_26Y3', cmd_type: 'value' }
              ],
              chart: {
                type: 'FaceplateValveChart',
                props: {
                  name: 'Отопление 26Y3',
                  parent: 'HeatView',
                  gap: 86400,
                  tags: [
                    { name: 'SP_26Y3', title: 'Уставка' },
                    { name: 'TE_26Y3', title: 'Температура' },
                    { name: 'Position_26Y3', title: 'Положение крана' }
                  ]
                }
              }
            }
          }
        },
        {
          l: { x: 152, y: 555, w: 74, h: 74 },
          p: { x: 360, y: 302, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M1',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'Pumps_11M1_GVS_STS', type: 'control3', cmd: 'Pumps_11M1_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 152, y: 672, w: 74, h: 74 },
          p: { x: 360, y: 420, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '11M2',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'Pumps_11M2_GVS_STS', type: 'control3', cmd: 'Pumps_11M2_GVS_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 925, y: 535, w: 74, h: 74 },
          p: { x: 615, y: 785, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '7M1',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'Pumps_7M1_OTO_STS', type: 'control3', cmd: 'Pumps_7M1_OTO_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        },
        {
          l: { x: 925, y: 652, w: 74, h: 74 },
          p: { x: 615, y: 904, w: 74, h: 74 },
          data: {
            type: 'FaceplateOnOffAuto',
            props: {
              name: '7M2',
              parent: 'HeatView',
              values: [
                { target: 'mode', tag: 'Pumps_7M2_OTO_STS', type: 'control3', cmd: 'Pumps_7M2_OTO_CMD', cmd_type: '11M_GVS' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>
