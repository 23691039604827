<script>
import BaseView from '../components/BaseView.vue'

export default {
  name: 'MainView',
  extends: BaseView,

  data () {
    return {
      layout: '01_MAIN',
      navigation: [
        { l: { x: 0, y: 221, w: 76, h: 68 }, p: { x: 280, y: 0, w: 76, h: 68 }, target: 'FaceplateLogs' },
        { l: { x: 92, y: 316, w: 256, h: 96 }, p: { x: 303, y: 176, w: 244, h: 94 }, target: 'Ahu4View' },
        { l: { x: 772, y: 224, w: 306, h: 79 }, p: { x: 620, y: 72, w: 217, h: 77 }, target: 'ChillerView' },
        { l: { x: 772, y: 316, w: 256, h: 96 }, p: { x: 571, y: 176, w: 244, h: 94 }, target: 'Ahu18View' },
        { l: { x: 432, y: 434, w: 256, h: 96 }, p: { x: 413, y: 330, w: 295, h: 94 }, target: 'Ahu3View' },
        { l: { x: 92, y: 554, w: 256, h: 95 }, p: { x: 303, y: 488, w: 244, h: 94 }, target: 'Ahu1View' },
        { l: { x: 772, y: 554, w: 256, h: 95 }, p: { x: 571, y: 488, w: 244, h: 94 }, target: 'Ahu2View' },
        { l: { x: 52, y: 670, w: 95, h: 94 }, p: { x: 303, y: 645, w: 244, h: 94 }, target: 'MixView' },
        { l: { x: 161, y: 670, w: 198, h: 94 }, p: { x: 571, y: 880, w: 244, h: 94 }, target: 'HeatView' },
        { l: { x: 373, y: 670, w: 199, h: 94 }, p: { x: 303, y: 760, w: 244, h: 94 }, target: 'VentView' },
        { l: { x: 585, y: 670, w: 200, h: 94 }, p: { x: 571, y: 760, w: 244, h: 94 }, target: 'GvsView' },
        { l: { x: 798, y: 670, w: 200, h: 94 }, p: { x: 303, y: 880, w: 244, h: 94 }, target: 'FloorView' },
        { l: { x: 1010, y: 670, w: 99, h: 94 }, p: { x: 571, y: 645, w: 244, h: 94 }, target: 'PumpsView' }
      ],
      statuses: [
        { l: { x: 92, y: 316, w: 256, h: 96 }, p: { x: 303, y: 176, w: 244, h: 94 }, tag: 'AHU4_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 772, y: 224, w: 306, h: 79 }, p: { x: 620, y: 72, w: 217, h: 77 }, tag: 'CHILL_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 772, y: 316, w: 256, h: 96 }, p: { x: 571, y: 176, w: 244, h: 94 }, tag: 'AHU18_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 432, y: 434, w: 256, h: 96 }, p: { x: 413, y: 330, w: 295, h: 94 }, tag: 'AHU3_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 92, y: 554, w: 256, h: 95 }, p: { x: 303, y: 488, w: 244, h: 94 }, tag: 'AHU1_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 772, y: 554, w: 256, h: 95 }, p: { x: 571, y: 488, w: 244, h: 94 }, tag: 'AHU2_STS', type: 'bit', options: { pos: 0 } },
        { l: { x: 52, y: 670, w: 95, h: 94 }, p: { x: 303, y: 645, w: 244, h: 94 }, tag: 'System_STS', type: 'bit', options: { pos: 2 } }
      ],
      values: [
        { l: { x: 215, y: 267 }, p: { x: 495, y: 45 }, align: 'end', tag: 'tmp4', type: 'season' },
        { l: { x: 215, y: 287.25 }, p: { x: 495, y: 65.25 }, align: 'end', postfix: ' °C', tag: 'TE_0', type: 'short', options: { precision: 1 } },
        { l: { x: 240, y: 287.25 }, p: { x: 530, y: 65.25 }, align: 'start', postfix: ' °C', tag: 'SP_0', type: 'short', options: { precision: 1 } },
        { l: { x: 108, y: 348 }, p: { x: 315, y: 200 }, align: 'start', postfix: ' °C', tag: 'AHU4_Sp', type: 'short', options: { precision: 1 } },
        { l: { x: 333, y: 348 }, p: { x: 528, y: 200 }, align: 'end', postfix: ' °C', tag: 'AHU4_TE1', type: 'short', options: { precision: 1 } },
        { l: { x: 790, y: 348 }, p: { x: 585, y: 200 }, align: 'start', postfix: ' °C', tag: 'AHU18_Sp', type: 'short', options: { precision: 1 } },
        { l: { x: 1012, y: 348 }, p: { x: 792, y: 200 }, align: 'end', postfix: ' °C', tag: 'AHU18_TE1', type: 'short', options: { precision: 1 } },
        { l: { x: 673, y: 466 }, p: { x: 688, y: 355 }, align: 'end', postfix: ' °C', tag: 'AHU3_TE1', type: 'short', options: { precision: 1 } },
        { l: { x: 448, y: 466 }, p: { x: 428, y: 355 }, align: 'start', postfix: ' °C', tag: 'AHU3_Sp', type: 'short', options: { precision: 1 } },
        { l: { x: 108, y: 588 }, p: { x: 320, y: 510 }, align: 'start', postfix: ' °C', tag: 'AHU1_SP', type: 'short', options: { precision: 1 } },
        { l: { x: 333, y: 588 }, p: { x: 528, y: 510 }, align: 'end', postfix: ' °C', tag: 'TE2a', type: 'short', options: { precision: 1 } },
        { l: { x: 790, y: 588 }, p: { x: 590, y: 510 }, align: 'start', postfix: ' °C', tag: 'AHU2_Sp', type: 'short', options: { precision: 1 } },
        { l: { x: 1012, y: 588 }, p: { x: 792, y: 510 }, align: 'end', postfix: ' °C', tag: 'TE5a', type: 'short', options: { precision: 1 } },
        { l: { x: 175, y: 700 }, p: { x: 590, y: 900 }, align: 'start', postfix: ' °C', tag: 'SP_26Y1', type: 'short', options: { precision: 1 } },
        { l: { x: 346, y: 700 }, p: { x: 792, y: 900 }, align: 'end', postfix: ' °C', tag: 'TE_26Y1', type: 'short', options: { precision: 1 } },
        { l: { x: 390, y: 700 }, p: { x: 320, y: 785 }, align: 'start', postfix: ' °C', tag: 'SP_26Y2', type: 'short', options: { precision: 1 } },
        { l: { x: 556, y: 700 }, p: { x: 528, y: 785 }, align: 'end', postfix: ' °C', tag: 'TE_26Y2', type: 'short', options: { precision: 1 } },
        { l: { x: 600, y: 700 }, p: { x: 590, y: 785 }, align: 'start', postfix: ' °C', tag: 'SP_26Y3', type: 'short', options: { precision: 1 } },
        { l: { x: 770, y: 700 }, p: { x: 792, y: 785 }, align: 'end', postfix: ' °C', tag: 'TE_26Y3', type: 'short', options: { precision: 1 } },
        { l: { x: 815, y: 700 }, p: { x: 320, y: 900 }, align: 'start', postfix: ' °C', tag: 'SP_5Y2', type: 'short', options: { precision: 1 } },
        { l: { x: 983, y: 700 }, p: { x: 528, y: 900 }, align: 'end', postfix: ' °C', tag: 'TE_5Y2', type: 'short', options: { precision: 1 } }
      ],
      alarms: [
        { l: { x: 781, y: 233, r: 8 }, p: { x: 828, y: 81, r: 8 }, tag: 'CHILL_ALM', type: 'short' },
        { l: { x: 781, y: 233, r: 8 }, p: { x: 828, y: 81, r: 8 }, tag: 'FANCOIL_ALM', type: 'short' },
        { l: { x: 338, y: 326, r: 8 }, p: { x: 539, y: 184, r: 8 }, tag: 'AHU4_ALM', type: 'short' },
        { l: { x: 1018, y: 326, r: 8 }, p: { x: 806, y: 184, r: 8 }, tag: 'AHU18_ALM', type: 'short' },
        { l: { x: 678, y: 444, r: 8 }, p: { x: 699, y: 339, r: 8 }, tag: 'AHU3_ALM', type: 'short' },
        { l: { x: 339, y: 563, r: 8 }, p: { x: 539, y: 497, r: 8 }, tag: 'AHU1_ALM', type: 'short' },
        { l: { x: 1018, y: 563, r: 8 }, p: { x: 806, y: 497, r: 8 }, tag: 'AHU2_ALM', type: 'short' },
        { l: { x: 138, y: 679, r: 8 }, p: { x: 539, y: 653, r: 8 }, tag: 'AHU11_ALM', type: 'short' },
        { l: { x: 138, y: 679, r: 8 }, p: { x: 539, y: 653, r: 8 }, tag: 'AHU12_ALM', type: 'short' },
        { l: { x: 138, y: 679, r: 8 }, p: { x: 539, y: 653, r: 8 }, tag: 'AHU14_15_ALM', type: 'short' },
        { l: { x: 138, y: 679, r: 8 }, p: { x: 539, y: 653, r: 8 }, tag: 'AHU17_ALM', type: 'short' },
        { l: { x: 350, y: 679, r: 8 }, p: { x: 806, y: 888, r: 8 }, tag: 'Pumps_7M1_OTO_ALM', type: 'short' },
        { l: { x: 350, y: 679, r: 8 }, p: { x: 806, y: 888, r: 8 }, tag: 'Pumps_7M2_OTO_ALM', type: 'short' },
        { l: { x: 562, y: 679, r: 8 }, p: { x: 539, y: 770, r: 8 }, tag: 'Pumps_7M3_VNT_ALM', type: 'short' },
        { l: { x: 562, y: 679, r: 8 }, p: { x: 539, y: 770, r: 8 }, tag: 'Pumps_7M4_VNT_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'Pumps_11M1_GVS_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'Pumps_11M2_GVS_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'Pumps_11M3_GVS_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'Pumps_11M4_GVS_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'Pumps_11M5_GVS_ALM', type: 'short' },
        { l: { x: 775, y: 679, r: 8 }, p: { x: 806, y: 770, r: 8 }, tag: 'BOILER_ALM', type: 'short' },
        { l: { x: 988, y: 679, r: 8 }, p: { x: 539, y: 888, r: 8 }, tag: 'Pumps_1M2_FL_ALM', type: 'short' },
        { l: { x: 1099, y: 679, r: 8 }, p: { x: 806, y: 653, r: 8 }, tag: 'Pumps_M1_PTP_ALM', type: 'short' },
        { l: { x: 1099, y: 679, r: 8 }, p: { x: 806, y: 653, r: 8 }, tag: 'Pumps_M2_PTP_ALM', type: 'short' },
        { l: { x: 1099, y: 679, r: 8 }, p: { x: 806, y: 653, r: 8 }, tag: 'SOLENOID_OTO_ALM', type: 'short' },
        { l: { x: 1099, y: 679, r: 8 }, p: { x: 806, y: 653, r: 8 }, tag: 'SOLENOID_VENT_ALM', type: 'short' }
      ],
      multistates: [
        { l: { x: 143, y: 250 }, p: { x: 423, y: 27 }, cx: 37, cy: 10, tag: 'tmp4', type: 'ushort', el: 'season' }
      ],
      faceplates: [
        {
          l: { x: 90, y: 252, w: 170, h: 45 },
          p: { x: 360, y: 35, w: 155, h: 48 },
          data: {
            type: 'FaceplateSeason',
            props: {
              name: 'Отопительный сезон',
              parent: 'MainView',
              values: [
                { target: 'season', tag: 'tmp4', type: 'ushort' },
                { target: 'te', tag: 'TE_0', type: 'short', options: { precision: 1 } },
                { target: 'te_sp', tag: 'SP_0', type: 'short', cmd: 'SP_0', cmd_type: 'value' }
              ]
            }
          }
        }
      ]
    }
  }
}
</script>
