<template>
  <div id="faceplate">
    <div id="header">
      <span>Журнал событий</span>
      <div id="close" @click="$emit('navigate', 'MainView')"></div>
    </div>
    <div id="body">
      <table>
        <th>Метка времени</th>
        <th>Логин</th>
        <th>IP</th>
        <th>Категория</th>
        <th>Описание</th>
        <tr v-for="event in events" :key="event.id">
          <td>{{ formatDate(Date.parse(event.datetime)) }}</td>
          <td>{{ event.login }}</td>
          <td>{{ event.ip }}</td>
          <td>{{ event.category }}</td>
          <td>{{ event.description }}</td>
        </tr>
      </table>
    </div>
    <footer>
      <div ref="infinitescrolltrigger" id="scoll-trigger"></div>
      <div class="circle-loader" v-if="showLoader"></div>
    </footer>
  </div>
</template>

<script>
import { HTTP } from '../http-common'

export default {
  name: 'EventScroll',

  data () {
    return {
      limit: 50,
      page: 0,
      isLastPage: false,
      showLoader: false,
      events: []
    }
  },

  mounted () {
    this.scrollTrigger()
  },

  methods: {
    scrollTrigger () {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0 && !this.isLastPage) {
            this.showLoader = true
            this.getEvents()
          }
        })
      })
      observer.observe(this.$refs.infinitescrolltrigger)
    },
    getEvents () {
      HTTP.post('/events/', JSON.stringify({
        limit: this.limit,
        offset: this.page++ * this.limit
      }))
        .then(response => {
          if (response.status !== 200) return
          this.isLastPage = response.data.isLastPage
          this.events = this.events.concat(response.data.data)
          this.showLoader = false
        })
        .catch(e => {
          console.log(e)
        })
    },
    formatDate (date) {
      var d = new Date(date)
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
    }
  }
}
</script>

<style scoped>
  #faceplate {
    margin: 10px auto;
    background: transparent;
    border: 2px solid #4d4e49;
    border-radius: 20px 20px;
    padding: 0;
    position: relative;
    text-align: center;
  }

  #header {
    padding: 20px 0;
    text-align: center;
  }

  #close{
    position: absolute;
    top: -1px;
    right: -1px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
  }

  #close:before,
  #close:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 5px;
    width: 32px;
    height: 1px;
    background: #4d4e49;
  }

  #close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  table {
    width: 100%;
  }
  th, tr, td {
    border: 1px solid #4d4e49;
  }
  footer {
    position: relative;
    width: 100%;
    min-height: 20px;
  }
  #scroll-trigger {
    height: 50px;
  }
  .circle-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .2);
    border-top: 5px solid #fff;
    animation: animate 1.5s infinite linear;
  }
  @keyframes animate {
    0% {
      transform: translate(-50%,-50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%,-50%) rotate(360deg);
    }
  }
  @media screen and (orientation: portrait) and (max-width: 1024px) {
  #faceplate {
    font-size: 50%;
  }
}
</style>
