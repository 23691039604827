<template>
  <div>
    <div class="alarm_row" v-for="alarm in alarms" :key="alarm.id">{{ new Date(alarm.datetime * 1000).toLocaleString("ru-RU") }} [ {{ alarm.appearance }} ] {{ alarm.description }}</div>
  </div>
</template>

<script>
import { HTTP } from '../http-common'

export default {
  name: 'AlarmLog',
  data () {
    return {
      timerMain: undefined,
      alarms: []
    }
  },

  beforeMount () {
    this.main()
    this.timerMain = setInterval(this.main, 1000)
  },

  beforeDestroy () {
    clearInterval(this.timerMain)
  },

  methods: {
    main () {
      HTTP.get('/alarms/')
        .then(response => {
          if (response.status !== 200) return
          this.alarms = response.data.data
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alarm_row {
    background-color: red;
    color: white;
    border-bottom: 1px white solid;
    padding-left: 5px;
  }
  @media screen and (orientation: portrait) and (max-width: 1024px) {
    .alarm_row {
      font-size: 50%;
    }
  }
</style>
