<template>
  <div id="login-form">
    <h1>Авторизация в системе</h1>
    <fieldset>
      <input v-model="login" type="login" placeholder="Логин" required >
      <input v-model="password" type="password" placeholder="Пароль" required>
      <input @click="tryLogin" type="submit" value="ВОЙТИ">
      <footer class="clearfix">
        <p @click="$emit('back')"><span style="cursor: pointer;">Отмена</span></p>
      </footer>
    </fieldset>
  </div>
</template>

<script>
import { HTTP } from '../http-common'
import { mapState } from 'vuex'

export default {
  name: 'LoginView',
  props: {
    msg: String
  },

  data () {
    return {
      login: undefined,
      password: undefined
    }
  },

  computed: mapState([
    'token'
  ]),

  methods: {
    tryLogin () {
      HTTP.post('/login/', JSON.stringify({ login: this.login, password: this.password }))
        .then(response => {
          if (response.data.result) {
            this.$store.state.token = response.data.token
            this.$emit('back')
          } else this.login = this.password = undefined
        })
    },

    test () {
      HTTP.get('/test/')
        .then(response => {
          console.log(response.data)
        }).catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 { margin: 0; }

a {
  color: #999;
  text-decoration: none;
}

a:hover { color: #1dabb8; }

fieldset {
  border: none;
  margin: 0;
}

input {
  border: none;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
}

input[type="submit"] { cursor: pointer; }

.clearfix { *zoom: 1; }
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}
.clearfix:after { clear: both; }

/* ---------- LOGIN-FORM ---------- */

#login-form {
  margin: 150px auto;
  width: 300px;
}

#login-form h1 {
  background-color: #282830;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 14px;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
}

#login-form fieldset {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 20px;
  position: relative;
}

#login-form fieldset:before {
  background-color: #fff;
  content: "";
  height: 8px;
  left: 50%;
  margin: -4px 0 0 -4px;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
}

#login-form input {
  font-size: 14px;
}

#login-form input[type="login"],
#login-form input[type="password"] {
  border: 1px solid #dcdcdc;
  padding: 12px 10px;
  width: 238px;
}

#login-form input[type="login"] {
  border-radius: 3px 3px 0 0;
}

#login-form input[type="password"] {
  border-top: none;
  border-radius: 0px 0px 3px 3px;
}

#login-form input[type="submit"] {
  background: #1dabb8;
  border-radius: 3px;
  color: #fff;
  float: right;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 20px;
}

#login-form input[type="submit"]:hover { background: #198d98; }

#login-form footer {
  font-size: 12px;
  margin-top: 16px;
}
</style>
