import Vue from 'vue'
import Vuex from 'vuex'
import { HTTP } from '../http-common'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: 'empty',
    tags: [
      { name: 'test0', value: 0, desired: undefined, protect: undefined, transient: true },
      { name: 'test1', value: 1, desired: undefined, protect: undefined, transient: true },
      { name: 'test100', value: 100, desired: undefined, protect: undefined, transient: true },
      { name: 'A0_CONNECT', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU11_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU11_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU11_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU11_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU12_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU12_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU12_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU12_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU14_15_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU14_15_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU14_15_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU14_15_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU17_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU17_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU17_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU17_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_CWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_D1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_D2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_HWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_I1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_I2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_P1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_P2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_TE1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_TE2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU18_TE3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_D1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_D2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_I1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_I2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_P1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_P2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_recup', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_SP', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU1_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_D1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_D2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_I1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_I2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_P1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_P2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_recup', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU2_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_CWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_D1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_D2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_HWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_I1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_I2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_P1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_P2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_TE1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_TE2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU3_TE3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_CWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_D1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_D2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_HWV', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_I1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_I2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_P1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_P2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_STS1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_TE1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_TE2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'AHU4_TE3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'BOILER_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'BOILER_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'BOILER_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'CHILL_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'CHILL_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'CHILL_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Common1_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Common_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'FANCOIL_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'FANCOIL_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'FANCOIL_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_20Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_26Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_26Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_26Y3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_27Y4', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Manual_5Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_20Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_25Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_26Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_26Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_26Y3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_27Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_27Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_27Y3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_27Y4', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Position_5Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M1_GVS_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M1_GVS_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M1_GVS_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M2_GVS_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M2_GVS_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M2_GVS_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M3_GVS_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M3_GVS_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M3_GVS_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M4_GVS_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M4_GVS_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M4_GVS_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M5_GVS_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M5_GVS_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_11M5_GVS_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_1M2_FL_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_1M2_FL_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_1M2_FL_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M1_OTO_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M1_OTO_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M1_OTO_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M2_OTO_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M2_OTO_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M2_OTO_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M3_VNT_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M3_VNT_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M3_VNT_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M4_VNT_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M4_VNT_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_7M4_VNT_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M1_PTP_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M1_PTP_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M1_PTP_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M2_PTP_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M2_PTP_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'Pumps_M2_PTP_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'recup3_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'recup4_Sp', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_20Y1_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_20Y1_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y1_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y1_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y2_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y2_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y3_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_26Y3_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_27Y4_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_27Y4_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_5Y2_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'REG_5Y2_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_OTO_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_OTO_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_OTO_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_VENT_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_VENT_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SOLENOID_VENT_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_0', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_20Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_26Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_26Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_26Y3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_27Y4', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'SP_5Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'System_ALM', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'System_CMD', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'System_STS', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE10a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE12a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE13a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE14a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE1a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE2a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE3a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE4a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE5a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE6a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE7a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE8a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE9a', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_0', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_20Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_26Y1', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_26Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_26Y3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_27Y4', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'TE_5Y2', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'tmp3', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'tmp4', value: undefined, desired: undefined, protect: undefined, d: 0 },
      { name: 'tmp5', value: undefined, desired: undefined, protect: undefined, d: 0 }
    ]
  },
  getters: {
    getTags: state => {
      return state.tags
    },
    getNotReliable: state => {
      return state.tags.filter(x => x.transient === undefined).filter(x => (Math.round(Date.now() / 1000) - x.d) > 15).map(x => x.name)
    },
    isDataReliable: state => {
      return state.tags.filter(x => x.transient === undefined).filter(x => (Math.round(Date.now() / 1000) - x.d) > 15).length === 0
    }
  },
  actions: {
    readTags ({ commit }) {
      HTTP.get('tags/export/')
        .then(response => {
          if (response.status !== 200) return
          console.log(response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    fetchTags ({ commit }) {
      HTTP.post('data/last/', JSON.stringify(this.state.tags.filter(x => x.transient === undefined).map(x => x.name)))
        .then(response => {
          if (response.status !== 200) return
          commit('UPDATE_TAGS', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    UPDATE_TAGS (state, data) {
      data.forEach(element => {
        const tag = state.tags.find(x => x.name === element.tag)
        if (tag === undefined) {
          console.log('Tag not attached to view: ' + element.tag)
          return
        }
        if (tag.protect !== undefined && Date.now() < tag.protect) return
        if (tag.desired !== undefined && Number(tag.desired) !== Number(element.values[0].v)) {
          console.log(`Tag ${tag.name} has value ${element.values[0].v} but desired value is ${tag.desired}`)
          tag.desired = undefined
        }
        tag.value = element.values[0].v
        tag.d = element.values[0].d
        tag.protect = undefined
      })
    }
  }
})

export default store
