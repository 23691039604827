<template>
  <div id="faceplate">
    <div id="header">
      <span>Журналы</span>
      <div id="close" @click="$emit('navigate', 'MainView')"></div>
    </div>
    <div id="body">
      <div id="controls">
        <div class="btn" @click="tryNavigate('AlarmScroll')">ОШИБКИ</div>
        <div class="btn" @click="tryNavigate('EventScroll')">СОБЫТИЯ</div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FaceplateLogs',

  methods: {
    tryNavigate (target) {
      if (this.$store.state.token === 'empty') this.$emit('navigate', 'LoginView')
      else this.$emit('navigate', target)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#faceplate {
  margin: auto;
  width: 300px;
  background: transparent;
  border: 2px solid #4d4e49;
  border-radius: 20px 20px;
  padding: 0;
  position: relative;
}

#header {
  padding: 20px 0;
  text-align: center;
}

#close{
    position: absolute;
    top: -1px;
    right: -1px;
    border-left: 1px solid #4d4e49;
    border-bottom: 1px solid #4d4e49;
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
}

#close:before,
#close:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 5px;
    width: 32px;
    height: 1px;
    background: #4d4e49;
}

#close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#controls {
  text-align: center;
}

.btn {
  height: 30px;
  line-height: 30px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: #4d4e49;
  color: white;
}
</style>
